import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { auth } from "../db/firebase.js";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const TIMEOUT_DURATION = 2 * 60 * 1000;
  const navigate = useNavigate();

  const loginUser = async (email, password) => {
    setLoading(true);
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      return result;
    } finally {
      setLoading(false);
    }
  };

  const logoutUser = async () => {
    setLoading(true);
    try {
      await signOut(auth);
      sessionStorage.removeItem("sessionExpiration");
      navigate("/");
    } finally {
      setLoading(false);
    }
  };

  const checkForInactivity = () => {
    const expireTime = sessionStorage.getItem("sessionExpiration");
    if (expireTime && Date.now() > parseInt(expireTime, 10)) {
      logoutUser();
    }
  };

  const updateExpireTime = () => {
    const expireTime = Date.now() + TIMEOUT_DURATION;
    sessionStorage.setItem("sessionExpiration", expireTime);
  };

  const throttleFunction = _.throttle(updateExpireTime, 1000);

  useEffect(() => {
    if (user) {
      const interval = setInterval(checkForInactivity, 10000);

      window.addEventListener("mousemove", throttleFunction);
      window.addEventListener("click", throttleFunction);
      window.addEventListener("keydown", throttleFunction);

      return () => {
        clearInterval(interval);
        window.removeEventListener("mousemove", throttleFunction);
        window.removeEventListener("click", throttleFunction);
        window.removeEventListener("keydown", throttleFunction);
      };
    }
  }, [user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
      if (user) {
        updateExpireTime();
      }
    });
    return () => unsubscribe();
  }, []);

  const authValue = {
    user,
    loginUser,
    logoutUser,
    loading,
  };

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
