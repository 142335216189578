import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaArrowLeft, FaRegUser } from "react-icons/fa";
import { MdLockOutline } from "react-icons/md";
import Navbar from "../components/navbar.js";
import InputField from "../components/inputField.js";
import img_empty from "../assets/img_empty.svg";
import { FaRegTrashAlt } from "react-icons/fa";
import { RiKey2Line } from "react-icons/ri";
import toast from "react-hot-toast";
import axios from "axios";
import { AuthContext } from "../auth/AuthProvider.js";
import DeleteContent from "../components/deleteContent.js";
import Modal from "../components/common/modal.js";
import {
  storage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  db,
} from "../db/firebase.js";
import { v4 as uuidv4 } from "uuid";
import { Switch } from "antd";
import cardknox from "../assets/cardknox.png";
import { doc, updateDoc } from "firebase/firestore";

const CreateNewPOS = ({ onUserChange, onDeleteUser }) => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const userData = location.state?.userData || null;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const imgRef = useRef();
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [newPOS, setNewPOS] = useState({
    title: userData?.title || "",
    subTitle: userData?.subTitle || "",
    userName: userData?.userName || "",
    password: userData?.password || "",
    activeTime: userData?.activeTime || "",
    disableKiosk: userData?.disableKiosk || false,
    notes: userData?.notes || "",
    imageUrl: userData?.imageUrl || "",
    img: null,
    xKey: userData?.xKey || "",
    iFields: userData?.iFields || "",
  });
  const [keyOperations, setKeyOperations] = useState({
    xKey: {
      isLoading: false,
      operation: userData?.xKey ? "remove" : "save",
    },
    iFields: {
      isLoading: false,
      operation: userData?.iFields ? "remove" : "save",
    },
  });

  useEffect(() => {
    if (isSubmit) {
      const errors = validate(newPOS);
      setFormErrors(errors);
    }
  }, [newPOS]);

  const validate = (values) => {
    const errors = {};
    if (!values.title) errors.title = "Title is required!";
    if (!values.subTitle) errors.subTitle = "SubTitle is required!";
    if (!values.userName) errors.userName = "Username is required!";
    if (!values.password) {
      errors.password = "Password is required!";
    } else if (values.password.length < 4) {
      errors.password = "Password must be more than 4 characters";
    } else if (values.password.length > 10) {
      errors.password = "Password cannot exceed more than 10 characters";
    }
    if (!values.imageUrl && !values.img) errors.imageUrl = "Required!";
    if (!values.activeTime) errors.activeTime = "Time is required!";
    if (!values.notes) errors.notes = "Notes are required!";
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewPOS({
      ...newPOS,
      [name]: value,
    });
  };

  const handleUploadClick = () => {
    imgRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewPOS({
        ...newPOS,
        imageUrl: URL.createObjectURL(file),
        img: file,
      });
    } else {
      setNewPOS({
        ...newPOS,
        imageUrl: "",
        img: null,
      });
    }
  };

  const handleDeleteImage = () => {
    setNewPOS({
      ...newPOS,
      imageUrl: "",
      img: null,
    });
  };

  const handleCreateNewUser = async (e) => {
    e.preventDefault();

    const errors = validate(newPOS);
    setFormErrors(errors);
    setIsSubmit(true);

    if (Object.keys(errors).length === 0) {
      setLoading(true);

      try {
        toast.loading("Please wait...");
        let imageUrl = newPOS.imageUrl;
        if (newPOS.img) {
          const storageRef = ref(
            storage,
            `users/${uuidv4()}_${newPOS.img.name}`
          );
          const snapshot = await uploadBytes(storageRef, newPOS.img);
          imageUrl = await getDownloadURL(snapshot.ref);
        }

        const payload = { ...newPOS, imageUrl };
        let res;
        if (userData?.id) {
          res = await axios.put(
            `${process.env.REACT_APP_HOST_URL}/api/user/updateUser/${userData?.id}`,
            payload,
            {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: `Bearer ${user.accessToken}`,
              },
            }
          );
        } else {
          res = await axios.post(
            `${process.env.REACT_APP_HOST_URL}/api/user/createUser`,
            payload,
            {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: `Bearer ${user.accessToken}`,
              },
            }
          );
        }

        if (res && res.data.success) {
          toast.dismiss();
          toast.success(res.data.message);
          onUserChange(res.data.data);
          navigate("/admin");
        } else {
          toast.dismiss();
          toast.error(res.data.message);
        }
      } catch (error) {
        console.error(error);
        toast.dismiss();
        toast.error("Something went wrong");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleBack = () => {
    navigate("/admin");
  };

  const handleDeleteImageFromStorage = async () => {
    if (newPOS.imageUrl && newPOS.img) {
      const imageRef = ref(storage, newPOS.imageUrl);
      try {
        await deleteObject(imageRef);
      } catch (error) {
        console.log("Failed to delete image from storage", error);
      }
    }
  };

  const handleDelete = async () => {
    try {
      toast.loading("Please wait...");
      await handleDeleteImageFromStorage();
      const res = await axios.delete(
        `${process.env.REACT_APP_HOST_URL}/api/user/deleteUser/${userData?.id}`,
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );
      if (res && res.data.success) {
        toast.dismiss();
        toast.success(res.data && res.data.message);
        onDeleteUser({ deleted: true, id: userData?.id });
        navigate("/admin");
      } else {
        toast.dismiss();
        toast.error(res.data && res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      const errorMessage =
        error.response?.data?.message || "Something went wrong";
      toast.error(errorMessage);
    }
  };

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const onChangeKiosk = (checked) => {
    setNewPOS({ ...newPOS, disableKiosk: checked });
  };

  useEffect(() => {
    if (!userData?.id) {
      setNewPOS({
        title: "",
        subTitle: "",
        userName: "",
        password: "",
        activeTime: "",
        disableKiosk: false,
        notes: "",
        imageUrl: "",
        img: null,
      });
    }
  }, [userData?.id]);

  const handleKeyOperation = async (keyType, operation) => {
    try {
      if (!userData?.id) {
        return;
      }

      if (operation === "save" && !newPOS[keyType]) {
        return;
      }

      toast.loading("Please wait...");

      const userDocRef = doc(db, "users", userData.id);

      if (operation === "save") {
        await updateDoc(userDocRef, {
          [keyType]: newPOS[keyType],
        });

        onUserChange({
          id: userData?.id,
          ...newPOS,
          [keyType]: newPOS[keyType],
        });

        toast.dismiss();
        toast.success(`${keyType} saved successfully`);

        setKeyOperations((prev) => ({
          ...prev,
          [keyType]: {
            operation: "remove",
          },
        }));
      } else {
        await updateDoc(userDocRef, {
          [keyType]: "",
        });

        setNewPOS((prev) => ({
          ...prev,
          [keyType]: "",
        }));

        onUserChange({
          id: userData?.id,
          ...newPOS,
          [keyType]: "",
        });

        toast.dismiss();
        toast.success(`${keyType} removed successfully`);

        setKeyOperations((prev) => ({
          ...prev,
          [keyType]: {
            operation: "save",
          },
        }));
      }
    } catch (error) {
      console.error(error);
      toast.dismiss();
      toast.error("Something went wrong");
    }
  };

  const renderKeyField = (keyType, placeholder) => {
    return (
      <div className="flex flex-row justify-between gap-[15px] h-[42px]">
        <InputField
          placeholder={placeholder}
          leading={<RiKey2Line />}
          name={keyType}
          value={newPOS[keyType]}
          onChange={handleChange}
        />
        <button
          disabled={keyOperations[keyType].isLoading}
          style={{
            width: "105px",
            borderRadius: "16px",
            background:
              keyOperations[keyType].operation === "remove"
                ? "#D30D0D"
                : "linear-gradient(180deg, #E06F2C 0%, #FF9F66 100%)",
            boxShadow: "0px 21px 21px rgba(0, 0, 0, 0.16)",
            color: "#FFFFFF",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "24px",
            opacity: keyOperations[keyType].isLoading ? 0.7 : 1,
          }}
          onClick={() =>
            handleKeyOperation(keyType, keyOperations[keyType].operation)
          }
        >
          {keyOperations[keyType].isLoading
            ? "Loading..."
            : keyOperations[keyType].operation === "remove"
            ? "Remove"
            : "Save"}
        </button>
      </div>
    );
  };

  return (
    <>
      <div className="h-[90%] w-[100%] overflow-x-hidden overflow-y-auto custom-scrollbar flex justify-center ">
        <div
          style={{
            minHeight: "867px",
            maxWidth: "745px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="relative w-[100%] mb-[45px] mt-[53px]">
            <div
              className="absolute flex w-[67px] h-[100%] justify-between items-center hover:cursor-pointer"
              onClick={handleBack}
            >
              <div>
                <FaArrowLeft />
              </div>
              <div style={{ color: "#12141E" }}>Back</div>
            </div>
            <div
              style={{
                color: "#000000",
                fontWeight: 700,
                fontSize: "30px",
                textAlign: "center",
              }}
            >
              {userData?.id ? "POS ACCOUNT" : "POS NEW ACCOUNT"}
            </div>
          </div>

          <div
            className="flex flex-col sm:flex-row"
            style={{ display: "flex", gap: "17px" }}
          >
            <div className="w-[364px] h-[52px] space-y-1">
              <InputField
                placeholder={"POS Title"}
                name="title"
                value={newPOS.title}
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{formErrors.title}</p>
            </div>
            <div className="w-[364px] h-[52px] space-y-1">
              <InputField
                placeholder={"Sub Title"}
                name={"subTitle"}
                value={newPOS.subTitle}
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{formErrors.subTitle}</p>
            </div>
          </div>

          <div
            style={{
              width: "671px",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "21px",
              textAlign: "center",
              color: "#12141E",
              marginTop: "25px",
            }}
          >
            This Login should be given to the user of this POS
          </div>

          <div
            className="flex flex-col sm:flex-row"
            style={{
              // display: "flex",
              gap: "17px",
              marginTop: "16px",
              width: "auto",
            }}
          >
            <div className="w-[364px] h-[52px] space-y-1">
              <InputField
                placeholder={"Username"}
                leading={<FaRegUser />}
                name="userName"
                value={newPOS.userName}
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{formErrors.userName}</p>
            </div>

            <div className="w-[364px] h-[52px] space-y-1">
              <InputField
                name="password"
                placeholder={"Password"}
                type="password"
                leading={<MdLockOutline />}
                value={newPOS.password}
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{formErrors.password}</p>
            </div>
          </div>

          <div
            className="sm:w-full flex flex-col sm:flex-row"
            style={{
              // display: "flex",
              gap: "54px",
              marginTop: "36px",
            }}
          >
            <div
              className="relative w-[118px] h-[118px] space-y-1"
              style={{
                borderRadius: "5px",
                backgroundColor: "#D9D9D9",
              }}
            >
              {newPOS.imageUrl ? (
                <>
                  <img
                    className="object-content h-[100%] w-[100%]"
                    style={{ borderRadius: "5px" }}
                    src={newPOS.imageUrl}
                    alt="img"
                  />
                  <button
                    className="absolute top-[73%] left-[8%] w-[30px] h-[30px]"
                    onClick={handleDeleteImage}
                  >
                    <FaRegTrashAlt color="red" />
                  </button>
                </>
              ) : (
                <div className="flex flex-column items-center h-[100%] w-[100%]">
                  <button onClick={handleUploadClick}>
                    <img className="" src={img_empty} alt="Empty" />
                    <input
                      type="file"
                      ref={imgRef}
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                      accept="image/*"
                    />
                  </button>
                </div>
              )}
              <p style={{ color: "red" }}>{formErrors.imageUrl}</p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  width: "144px",
                  height: "21px",
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "21px",
                  marginBottom: "8px",
                  marginLeft: "9px",
                  color: "#12141E",
                }}
              >
                Active time amount
              </div>
              <div className="w-[230px] h-[52px] space-y-1">
                <input
                  name="activeTime"
                  className="w-[230px] h-[52px] px-[42px] focus:outline-none"
                  placeholder="OCT 1, 2024"
                  style={{
                    borderRadius: "16px",
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "30px",
                    color: "#000000",
                  }}
                  value={newPOS.activeTime}
                  onChange={handleChange}
                />
                <p style={{ color: "red" }}>{formErrors.activeTime}</p>
              </div>
            </div>

            <div className="flex flex-row gap-4 h-full justify-center items-center">
              <span
                style={{
                  lineHeight: "24.75px",
                }}
              >
                Disable Kiosk
              </span>
              <Switch
                className={`${newPOS.disableKiosk ? "" : "custom-switch"}`}
                onChange={onChangeKiosk}
                checked={newPOS.disableKiosk}
                style={{
                  backgroundColor: newPOS.disableKiosk
                    ? "rgba(224, 111, 44, 1)"
                    : "#FFFFFF",
                }}
              />
            </div>
          </div>

          <div
            className="sm:w-full"
            style={{
              marginTop: "36px",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <div
              style={{
                marginLeft: "42px",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "21px",
                color: "#12141E",
              }}
            >
              Notes
            </div>
            <textarea
              className="p-[28px] focus:outline-none"
              name="notes"
              style={{ borderRadius: "16px" }}
              value={newPOS.notes}
              onChange={handleChange}
              rows={3}
            />
            <p style={{ color: "red" }}>{formErrors.notes}</p>
          </div>

          <div className="flex flex-row justify-between w-full h-[100px] mb-[29px]">
            <img
              src={cardknox}
              alt="cardknox image"
              className="w-[118px] object-contain"
            />

            <div className="flex flex-col justify-between w-full">
              {renderKeyField("xKey", "xKey")}
              {renderKeyField("iFields", "iFields")}
            </div>
          </div>

          <div
            className="w-full flex"
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              style={{
                width: "186px",
                height: "52px",
                borderRadius: "16px",
                background: "linear-gradient(180deg, #E06F2C 0%, #FF9F66 100%)",
                boxShadow: "0px 21px 21px rgba(0, 0, 0, 0.16)",
                color: "#FFFFFF",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "24px",
              }}
              onClick={handleCreateNewUser}
            >
              {loading
                ? "Please wait..."
                : userData?.id
                ? "Update User"
                : "Create New User"}
            </button>
          </div>

          {userData?.id && (
            <button
              className="btn mb-[83px] mt-[33px]"
              onClick={toggleDeleteModal}
              style={{
                backgroundColor: "transparent",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: "400",
                color: "#E06F2C",
                width: "188px",
                height: "34px",
                borderRadius: "18px",
                border: "1px",
                border: "1px solid #E06F2C",
              }}
            >
              Delete Account
            </button>
          )}
        </div>
      </div>

      <Modal
        isVisible={isDeleteModalOpen}
        onClose={toggleDeleteModal}
        backgroundColor={"white"}
      >
        <DeleteContent
          handleClose={toggleDeleteModal}
          handleDelete={handleDelete}
        />
      </Modal>
    </>
  );
};

export default CreateNewPOS;
